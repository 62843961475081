import { FC, ReactElement } from 'react'
import styles from './index.module.scss'

type Props = {
  title: string
  renderRightContent?: () => ReactElement
}

export const PageHeader: FC<Props> = ({ title, renderRightContent }) => {
  return (
    <div className='container'>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          {renderRightContent && <div className={styles.right}>{renderRightContent()}</div>}
        </div>
      </div>
    </div>
  )
}
