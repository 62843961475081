import { FC } from 'react'
import { PageTransition } from 'next-page-transitions'
import { useRouter } from 'next/router'
import { GlobalHeader } from '@components-v2/organisms/GlobalHeader'
import { GlobalFooter } from '@components-v2//organisms/GlobalFooter'
import { ToastManager } from '@components-v2/organisms/ToastManager'
import styles from './index.module.scss'

type Props = {
  withContainer?: boolean
}

/**
 * サインインやサインアップページなど、サイドバーが不要な場合のテンプレート
 */
export const DefaultPageTemplate: FC<Props> = ({ children, withContainer = true }) => {
  const router = useRouter()

  return (
    <div className={styles.container}>
      <GlobalHeader />
      <main className={styles.main}>
        {withContainer ? (
          <div className='container'>
            <PageTransition timeout={300} classNames='page-transition'>
              <PageTransitionInner key={router.route}>{children}</PageTransitionInner>
            </PageTransition>
          </div>
        ) : (
          <PageTransition timeout={300} classNames='page-transition'>
            <PageTransitionInner key={router.route}>{children}</PageTransitionInner>
          </PageTransition>
        )}
      </main>
      <GlobalFooter />
      <ToastManager />
    </div>
  )
}

// PageTransitionに直でHTMLとなる要素を渡すとエラーを吐くので、そのエラーを潰す用に噛ませるComponent
const PageTransitionInner: FC = ({ children }) => {
  return <>{children}</>
}
