import { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { DefaultPageTemplate } from '@components/templates/DefaultPageTemplate'
import { ProfileSingleColumnContentTemplate } from '@components/templates/ProfileSingleColumnContentTemplate'
import { MyHead } from '@components/molecules/MyHead'
import { PageHeader } from '@/componentsV2/molecules/PageHeader'
import styles from './index.module.scss'

const Custom404: NextPage = () => {
  const { t } = useTranslation(['error'])
  return (
    <DefaultPageTemplate withContainer={false}>
      <MyHead title='404: Not Found' />
      <ProfileSingleColumnContentTemplate>
        <PageHeader title='404: Not Found' />
        <div className='container'>
          <div className={styles.body}>
            <p>{t('お探しのページは見つかりませんでした。')}</p>
          </div>
        </div>
      </ProfileSingleColumnContentTemplate>
    </DefaultPageTemplate>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    forcedTheme: 'light',
    ...(await serverSideTranslations(locale, ['global', 'error'])),
  },
})

export default Custom404
