import { FC, PropsWithChildren, ReactElement } from 'react'
import styles from './index.module.scss'

type Props = {
  header?: ReactElement
  hasHeaderImage?: boolean
}

export const ProfileSingleColumnContentTemplate: FC<PropsWithChildren<Props>> = ({
  children,
  header,
  hasHeaderImage = false,
}) => (
  <div className={styles.container}>
    <div className={hasHeaderImage ? styles.mainWithImg : styles.main}>
      {header && <div className={styles.header}>{header}</div>}
      {children}
    </div>
  </div>
)
